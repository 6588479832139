import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
/**
 * html生成pdf
 * 函数名 htmlToPdf
 * 参数  [title:Sring 文件名]、[pdfID:Sring 要导出的html元素]、[orientation:string 方向 可选l横向 p竖向]
 * 结果  返回pdf文件
 **/
export const htmlToPdf = (title, pdfID, orientation = 'l',picture=true) => {
  return new Promise((resolve, reject) => {
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      reject('截图打印暂不支持IE内核浏览器，请更换火狐或谷歌chrome内核浏览器，360等双核浏览器请切换至极速模式');
    }
    let element = document.querySelector(pdfID)
    if(picture){
      html2Canvas(element, {allowTaint: true, scale: 1.0}).then(function (canvas) {
          //获取canvas画布的宽高
          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
          //一页pdf显示html页面生成的canvas高度;
          let pageHeight = orientation === 'l' ? contentWidth / 841.89 * 592.28 : contentWidth / 592.28 * 841.89;
          //未生成pdf的html页面高度
          let leftHeight = contentHeight;
          //页面偏移
          let position = 0;
          //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          let imgWidth = orientation === 'l' ? 841.89 : 592.28;
          let imgHeight = (orientation === 'l' ? 841.89 : 592.28) / contentWidth * contentHeight;
          let pageData = canvas.toDataURL();
          let pdf = new JsPDF(orientation, 'pt', 'a4');
          //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          //当内容未超过pdf一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
              leftHeight -= pageHeight;
              position -= orientation === 'l' ? (592.28) : 841.89;
              //避免添加空白页
              if (leftHeight > 0) {
                pdf.addPage();
              }
            }
          }
          pdf.save(title + '.pdf');
          resolve('success')
        }
      )
    }
  })
}