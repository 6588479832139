<template>
  <div>
    <Spin fix v-show="loading">
      <Icon type="ios-loading" class="spin-icon-load" size="35"></Icon>
      <div>下载中，请稍后......</div>
    </Spin>
    <div class="monthlyReport" v-if="commentData">
      <div id="downPdf" style="padding: 0 50px;background: #f7f7f7">
        <div class="header">
          <b>{{ detail.iname }}</b>
          <a href="javascript:;" class="down" @click="down" v-if="!loading">
            <Icon type="md-cloud-download"/>
            下载报告</a>
        </div>
        <!-- 基本状况 -->
        <basic-overview :commentData="commentData" :policyData="policyData"></basic-overview>
        <!-- 服务情况总说明 -->
        <div class="serviceExplain">
          <Card :bordered="false" dis-hover>
            <b slot="title">服务情况总说明</b>
            <div class="content" id="serviceExplainContent">
              <div>(1)、服务项目总数：{{ policyData.policyCount }}个，服务总次数：{{ commentData.checkCount }}次；</div>
              <div>(2)、开展服务总次数分类统计：安责险风险排查服务：{{ commentData.checkData.azxCount }}次，专项检查服务：{{ commentData.checkData.specialCount }}次；</div>
              <div>(3)、房建：优良项目个数：{{ levelData['房建'] ? levelData['房建']['优良'] : 0 }}个，合格：{{ levelData['房建'] ? levelData['房建']['合格'] : 0 }}个，不合格：{{ levelData['房建'] ? levelData['房建']['不合格'] : 0 }}个；市政：合格：{{ levelData['市政'] ? levelData['市政']['合格'] : 0 }}个，不合格：{{ levelData['市政'] ? levelData['市政']['不合格'] : 0 }}个；</div>
              <div v-if="projectRate.length>0">(4)、隐患占比最多项目：{{ projectRate[0].projectName }}，隐患占比最少项目：{{ projectRate[projectRate.length - 1].projectName }}；</div>
              <!--          <div v-if="projectTotalScore.length>0">(5)、 得分最高项目：{{ projectTotalScore[0].projectName }}；得分最低项目：{{ projectTotalScore[projectTotalScore.length - 1].projectName }}</div>-->
            </div>
          </Card>
        </div>
        <!-- 安责险服务情况 -->
        <task :projectData="commentData.projectData||[]"></task>
        <!-- 检查项目隐患占比 -->
        <project-danger-proportion :commentData="commentData"></project-danger-proportion>
        <!-- 概率分析 -->
        <probability-analysis :categoryData="commentData.categoryData||{}"></probability-analysis>
        <!-- 隐患整改统计 -->
        <rectification-statistics :checkIds="checkIds"></rectification-statistics>
        <!-- 服务总结 -->
        <div class="serviceSummary">
          <div class="title">
            <b>服务总结</b>
          </div>
          <div class="content" v-html="detail.desc"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import basicOverview from '@/views/reportManager/month/detail/basicOverview/index.vue'
import task from '@/views/reportManager/month/detail/task/index.vue'
import projectDangerProportion from '@/views/reportManager/month/detail/projectDangerProportion/index.vue'
import probabilityAnalysis from '@/views/reportManager/month/detail/probabilityAnalysis/index.vue'
import rectificationStatistics from '@/views/reportManager/month/detail/rectificationStatistics/index.vue'
import {htmlToPdf} from "@/lib/util";

export default {
  name: 'monthlyReport',
  props: {detail: {type: Object, required: true, default: null}},
  data() {
    return {
      loading: false,
      checkIds: [],
      commentData: null,
      policyData: {},
    }
  },
  components: {
    basicOverview,
    task,
    projectDangerProportion,
    probabilityAnalysis,
    rectificationStatistics
  },
  computed: {
    levelData() {
      return this.commentData.levelData || {}
    },
    projectRate: function () {
      return (this.commentData.projectData || []).sort((a, b) => {
        return (b['rate'] || 0) - (a['rate'] || 0)
      })
    },
    projectTotalScore: function () {
      return (this.commentData.projectData || []).sort((a, b) => {
        return (b['totalScore'] || 0) - (a['totalScore'] || 0)
      })
    }
  },
  mounted() {
    let commentData = this.commentData = this.detail.data.commentData;
    this.policyData = this.detail.data.policyData
    this.checkIds = commentData.checkIds
  },
  methods: {
    down() {
      this.loading = true;
      this.$nextTick(() => {
        htmlToPdf(this.detail.iname + '的服务报告', '#downPdf').then(() => {
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@componentSpacing: 10px;
@componentRadius: 6px;
.monthlyReport {
  padding: 0 @componentSpacing;
  width: 100%;

  .header {
    position: relative;
    height: 90px;
    line-height: 90px;
    text-align: center;
    margin-bottom: @componentSpacing;
    border-radius: 0 0 @componentRadius @componentRadius;

    .down {
      position: absolute;
      right: 20px;
    }
  }

  .serviceExplain {
    margin-bottom: @componentSpacing;
    border-radius: @componentRadius;
    overflow: hidden;

    .ivu-card {
      .ivu-card-head {
        height: 60px;
        line-height: 60px;
      }

      .ivu-card-body {
        .content {
          flex-wrap: wrap;
          line-height: 30px;
          padding: 20px 0;
        }
      }
    }
  }

  .serviceSummary {
    .title {
      height: 80px;
      line-height: 80px;
      padding-left: 26px;
      margin-bottom: 5px;
      border-radius: @componentRadius;
    }

    .content {
      padding: 26px;
    }
  }
}
</style>