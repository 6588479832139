<template>
  <div class="task">
    <div class="title">
      <b>安责险服务情况</b>
    </div>
    <div class="content">
      <Card :bordered="false" dis-hover>
        <b slot="title">各项安责险服务内容汇总(隐患占比最高1-5)</b>
        <div style="height: 100%; width: 100%;">
          <ys-page-list
              ref="table"
              keyLabel="projectId"
              :tableHeight="350"
              :multiColumn="false"
              :footer="false"
              :header="false"
              :headerColumns="headerColumns"
              :tableData="tableData">
          </ys-page-list>
          <p style="text-align: right;line-height: 50px;color: #ed4014;">注: 项目列表详见附件</p>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import {fixed} from "ys-admin";

export default {
  name: 'task',
  props: {
    projectData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headerColumns: [
        {type: 'index', title: '序号', width: 60, align: 'center'},
        {width: 180, title: '保单号', key: 'policyNumber', align: 'center'},
        {minWidth: 230, title: '项目名称',tooltip:true, key: 'projectName', align: 'left'},
        {width: 80, title: '隐患总数', key: 'commentCount', align: 'center'},
        {width: 80, title: '重大隐患', key: 'majorDangerNum', align: 'center'},
        {width: 80, title: '较大隐患', key: 'moreDangerNum', align: 'center'},
        {width: 80, title: '一般隐患', key: 'commonlyDangerNum', align: 'center'},
        {width: 80, title: '轻微隐患', key: 'slightDangerNum', align: 'center'},
        {width: 80, title: '隐患占比', key: 'dangerProportion', align: 'center'},
        {width: 80, title: '服务次数', key: 'checkCount', align: 'center'},
      ],
    }
  },
  computed: {
    tableData: function () {
      return this.projectData.map((item) => {
        item.majorDangerNum = item.gradeMap['重大隐患'].count;
        item.moreDangerNum = item.gradeMap['较大隐患'].count;
        item.commonlyDangerNum = item.gradeMap['一般隐患'].count;
        item.slightDangerNum = item.gradeMap['轻微隐患'].count;
        item.dangerProportion = fixed(item.rate, 5, 2);
        return item;
      })
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
@componentSpacing: 20px;
@componentRadius: 6px;
.task {
  margin-bottom: @componentSpacing;

  .title {
    height: 80px;
    line-height: 80px;
    margin-bottom: 5px;
    padding-left: 26px;
    border-radius: @componentRadius;

  }

  .content {
    border-radius: @componentRadius;
    overflow: hidden;
    b{
      padding: @componentSpacing 0;
    }
    .ivu-card {
      .ivu-card-head {
        height: 60px;
        line-height: 60px;
      }
    }
  }
}
</style>