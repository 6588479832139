<template>
  <chartBox title="服务数据（个）">
    <div id="serviceData"></div>
  </chartBox>
</template>

<script>
// import ysAdmin from "ys-admin";
import * as echarts from 'echarts';
import chartBox from '@/views/reportManager/month/detail/basicOverview/chart/chartBox.vue'
export default {
  name: 'serviceData',
  props: {
    projectNum: {
      type: Number,
      default: 0
    },
    serviceNum: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: 'transparent'
            }
          },
          confine: true,
          formatter: '{b0}（个）: &nbsp;&nbsp;&nbsp; <b>{c0}</b>'
        },
        grid: {
          left: 0,
          right: 10,
          bottom:20,
          top: 20,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLine: { // 坐标轴线
              show: true,
              lineStyle: {
                // color: "#4e75d8"
              }
            },
            axisTick: { // 刻度
              show: false
            },
            axisLabel: { // 刻度标签
              show: true,
              interval: 0,
              margin: 20,
              // color: ysAdmin.color.primaryColor
            },
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                // color: 'rgba(255,255,255,0.2)'
              }
            },
            axisLabel: {
              show: true,
              // color: ysAdmin.color.primaryColor
            },
          }
        ],
        series: [{
          // 正面的柱子
          name: '项目数（个）',
          type: "bar",
          barWidth: 20, // 柱宽
          barGap: 0, // 不同系列的柱间距离
          data: [],
          itemStyle: {
            color: "#549ddf"
          },
          label: {
            show: true,
            position: 'top',
            offset: [5, -20],
            // color: ysAdmin.color.primaryColor,
          },
        },{
          // 侧面的柱子
          tooltip: {
            show: false,
          },
          type: 'bar',
          barWidth: 10,
          barGap: 0,
          data: [],
          itemStyle: {
            color: "#3b88c9"
          },
        },{
          // 顶部的盖子
          tooltip: {
            show: false
          },
          type: 'pictorialBar',
          itemStyle: {
            borderColor: '#47A6FF',
            color: '#549ddf',
          },
          symbol: 'path://M 0,0 l 120,0 l -30,60 l -120,0 z',
          symbolSize: ['30', '10'], // 大小，倾斜角度
          symbolOffset: ['0', '-11'], // 左右 ，上下
          symbolPosition: 'end',
          data: [],
          z: 3
        }]
      },
      myChart: {},
    }
  },
  components: {
    chartBox,
  },
  computed: {},
  mounted() {
    this.myChart = echarts.init(document.getElementById('serviceData'));
    this.getPolicyData();
  },
  methods: {
    getPolicyData() {
      const data = [this.projectNum, this.serviceNum]
      const sideData = this.$parent.sideData(data)
      this.option.xAxis[0].data = ["服务项目总数", "服务总次数"]
      this.option.series[0].data = this.option.series[2].data = data
      this.option.series[1].data = sideData

      this.myChart.clear();
      this.myChart.resize(
        {
          width:document.getElementById("serviceData").offsetWidth,
          height:document.getElementById("serviceData").offsetHeight
        }
      )
      this.myChart.setOption(this.option);
    },
  },
}
</script>

<style lang='less'>
#serviceData {
  width: 100%;
  height: 100%;
}
</style>