<template>
  <div class="rectification">
    <Card :bordered="false" dis-hover>
      <b slot="title">
        隐患整改统计
      </b>
      <div class="left">
        <div id="rectificationChart" style="height: 100%; width: 100%;"></div>
      </div>
      <div class="right">
        <div style="height: 50%; line-height: 25px;" v-html="dangerSummary.situation"></div>
        <!--        <div style="height: 50%; line-height: 25px;" v-html="dangerSummary.reason"></div>-->
      </div>
    </Card>
  </div>
</template>

<script>
import ysAdmin from "ys-admin";
import * as echarts from 'echarts';
import {StatusByCheck} from "@/api/brief";

export default {
  name: 'rectification',
  props: {
    checkIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: '',
          subtext: '项目总数',
          top: '40%',
          left: '36%',
          textStyle: {
            fontWeight: 500,
            lineHeight: 6,
            color: '#6ceaf4',
            fontSize: '25',
          },
          subtextStyle: {
            lineHeight: 0,
            color: '#6ceaf4',
            fontSize: '12',
          },
        },
        legend: {
          right: "30%",
          top: "30%",
          orient: "vertical",
          textStyle: {
            overflow: "break"
          },
        },
        series: [
          {
            name: '项目数(个)',
            type: 'pie',
            radius: ['50%', '60%'],
            center: ['40%', '50%'],
            itemStyle: {
              borderColor: ysAdmin.color.componentBackground,
              borderWidth: 5,
            },
            label: {
              show: false
            },
            data: []
          }
        ]
      },
      dangerSummary: {
        situation: '',
        reason: `<b>项目不配合、施工进度不允许等</b>`,
      }
    }
  },
  components: {},
  computed: {},
  mounted() {
    this.myChart = echarts.init(document.getElementById("rectificationChart"));
    this.getPolicyData();
  },
  methods: {
    getPolicyData() {
      if (!this.checkIds||this.checkIds.length===0) return;
      this.option.series[0].data = [];
      StatusByCheck({checkIds: this.checkIds}).then(res => {
        if (res.code === 200) {


          const data = res.data
          let nectifiedNum = 0
          let nectificationNum = 0
          let notRectifiedNum = 0
          for (let key in data.all) {
            let value = data.all[key]
            switch (key) {
              case '已完成':
                nectifiedNum += eval(Object.values(value).join("+"))
                break;
              case '待复核':
              case '待审核':
                nectificationNum += eval(Object.values(value).join("+"))
                break;
              case '待整改':
                notRectifiedNum += eval(Object.values(value).join("+"))
                break;
            }
          }

          this.option.title.text = ` ${nectifiedNum + nectificationNum + notRectifiedNum}`
          this.option.series[0].data = [
            {value: nectifiedNum, name: '已整改', itemStyle: {color: '#00ffda'}},
            {value: nectificationNum, name: '整改中', itemStyle: {color: '#00c5ff'}},
            {value: notRectifiedNum, name: '未整改', itemStyle: {color: '#ffd800'}},
          ]
          this.dangerSummary.situation = `<b>其中立即整改的有${nectifiedNum}个，隐患未整改的有${notRectifiedNum}个，逾期未整改的有${eval(Object.values(data.overdue['待整改']).join("+"))}个，严重及较大的隐患未整改的有${data.all['待整改']['较大隐患'] + data.all['待整改']['重大隐患']}个。</b>`

          this.myChart.clear();
          this.myChart.resize(
              {
                width: document.getElementById("rectificationChart").offsetWidth,
                height: document.getElementById("rectificationChart").offsetHeight
              }
          )
          this.myChart.setOption(this.option);
        }
      })
    }

  }
}
</script>

<style lang="less">
@componentSpacing: 20px;
@componentRadius: 6px;
.rectification {
  border-radius: @componentRadius;
  margin-bottom: @componentSpacing;
  overflow: hidden;

  .ivu-card-head {
    height: 60px;
    line-height: 60px;
  }

  .ivu-card-body {
    display: flex;

    .left {
      height: 220px;
      width: 50%;
      align-self: center;
    }

    .right {
      flex: 1;
    }
  }
}

</style>