<template>
  <chartBox title="工程分类统计（个）">
    <div id="categoryStatistics"></div>
  </chartBox>
</template>

<script>
import * as echarts from 'echarts';
import { getBaseDataName } from "ys-admin";
import chartBox from '@/views/reportManager/month/detail/basicOverview/chart/chartBox.vue'
export default {
  name: 'categoryStatistics',
  props: {
    projectCategory: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: 'transparent'
            }
          },
          confine: true
        },

        grid: {
          left: 0,
          right: 10,
          bottom: 5,
          top: 20,
          containLabel: true
        },

        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: true,
              lineStyle: {
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: true,
              interval: 0,
              margin: 30,
              rotate: 20,
              align: "center"
            },
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
            },
          }
        ],
        series: [{
          name: '项目数（个）',
          type: "bar",
          barWidth: 20,
          barGap: 0,
          data: [],
          itemStyle: {
            color: "#00d2b4"
          },
          label: {
            show: true,
            position: 'top',
            offset: [5, -20],
          },
        },{
          tooltip: {
            show: false,
          },
          type: 'bar',
          barWidth: 10,
          barGap: 0,
          data: [],
          itemStyle: {
            color: "#00d2b4"
          },
        },{
          tooltip: {
            show: false
          },
          type: 'pictorialBar',
          itemStyle: {
            borderColor: '#47A6FF',
            color: '#00d2b4',
          },
          symbol: 'path://M 0,0 l 120,0 l -30,60 l -120,0 z',
          symbolSize: ['30', '10'],
          symbolOffset: ['0', '-11'],
          symbolPosition: 'end',
          data: [],
          z: 3
        }]
      },
      myChart: {},
    }
  },
  components: {
    chartBox,
  },
  computed: {},
  mounted() {
    this.myChart = echarts.init(document.getElementById('categoryStatistics'));
    this.getPolicyData();
  },
  methods: {
    
    getPolicyData() {
      const data = this.projectCategory.sort((a, b) => {
        return a.cateId - b.cateId
      })
      const sideData = this.$parent.sideData(data.map(item => item.count * 1))
      this.option.xAxis[0].data = data.map(item => {
        return getBaseDataName('项目类型', item.cateId,  false)
      })
      this.option.series[0].data = this.option.series[2].data = data.map(item => item.count * 1)
      this.option.series[1].data = sideData

      this.myChart.clear();
      this.myChart.resize(
        {
          width:document.getElementById("categoryStatistics").offsetWidth,
          height:document.getElementById("categoryStatistics").offsetHeight
        }
      )
      this.myChart.setOption(this.option);
    },
  
  }
}
</script>

<style>
#categoryStatistics {
  width: 100%;
  height: 100%;
}
</style>