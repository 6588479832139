<template>
  <div class="projectDangerProportion">
    <Card :bordered="false" dis-hover>
      <b slot="title">十大项统计</b>
      <div style="height: 100%; width: 100%;">
        <ys-page-list
            ref="table"
            tableHeight="auto"
            :multiColumn="false"
            :footer="false"
            :isPage="false"
            :header="false"
            :headerColumns="headerColumns"
            :tableData="categoryData">
        </ys-page-list>
        <p style="margin: 30px 0 5px 15px;"><b>隐患分类分析总结：</b><br></p>
        <p style="margin-left: 15px; line-height: 30px;" v-html="summary"></p>
      </div>
    </Card>
  </div>
</template>

<script>
import {fixed} from "ys-admin";

export default {
  name: 'projectDangerProportion',
  props: {
    commentData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      headerColumns: [
        {type: 'index', title: '序号', width: 60, align: 'center'},
        {minWidth: 180, title: '检查项目', key: 'name', align: 'center'},
        {
          title: '隐患类型', align: 'center', children: [
            {
              title: '一般隐患', align: 'center', children: [
                {
                  width: 80, title: '数量', key: 'commonlyDangerNum', align: 'center', render: (h, params) => {
                    return h('span', params.row.gradeMap['一般隐患'].count)
                  },
                },
                {
                  width: 80, title: '概率', key: 'moreDangerNum', align: 'center', render: (h, params) => {
                    return h('span', {domProps: {innerHTML: fixed(params.row.gradeMap['一般隐患'].rate, 5, 2)}})
                  },
                },
                {
                  width: 80, title: '隐患占比', key: 'majorDangerNum', align: 'center', render: (h, params) => {
                    return h('span', params.index === this.categoryData.length - 1 ? {domProps: {innerHTML: fixed(params.row.gradeMap['一般隐患'].happendRate, 5, 2)}} : '-')
                  },
                },
              ]
            },
            {
              title: '轻微隐患', align: 'center', children: [
                {
                  width: 80, title: '数量', key: 'commonlyDangerNum', align: 'center', render: (h, params) => {
                    return h('span', params.row.gradeMap['轻微隐患'].count)
                  },
                },
                {
                  width: 80, title: '概率', key: 'moreDangerNum', align: 'center', render: (h, params) => {
                    return h('span', {domProps: {innerHTML: fixed(params.row.gradeMap['轻微隐患'].rate, 5, 2)}})
                  },
                },
                {
                  width: 80, title: '隐患占比', key: 'majorDangerNum', align: 'center', render: (h, params) => {
                    return h('span', params.index === this.categoryData.length - 1 ? {domProps: {innerHTML: fixed(params.row.gradeMap['轻微隐患'].happendRate, 5, 2)}} : '-')
                  },
                },
              ]
            },
            {
              title: '较大隐患', align: 'center', children: [
                {
                  width: 80, title: '数量', key: 'commonlyDangerNum', align: 'center', render: (h, params) => {
                    return h('span', params.row.gradeMap['较大隐患'].count)
                  },
                },
                {
                  width: 80, title: '概率', key: 'moreDangerNum', align: 'center', render: (h, params) => {
                    return h('span', {domProps: {innerHTML: fixed(params.row.gradeMap['较大隐患'].rate, 5, 2)}})
                  },
                },
                {
                  width: 80, title: '隐患占比', key: 'majorDangerNum', align: 'center', render: (h, params) => {
                    return h('span', params.index === this.categoryData.length - 1 ? {domProps: {innerHTML: fixed(params.row.gradeMap['较大隐患'].happendRate, 5, 2)}} : '-')
                  },
                },
              ]
            },
            {
              title: '重大隐患', align: 'center', children: [
                {
                  width: 80, title: '数量', key: 'commonlyDangerNum', align: 'center', render: (h, params) => {
                    return h('span', params.row.gradeMap['重大隐患'].count)
                  },
                },
                {
                  width: 80, title: '概率', key: 'moreDangerNum', align: 'center', render: (h, params) => {
                    return h('span', {domProps: {innerHTML: fixed(params.row.gradeMap['重大隐患'].rate, 5, 2)}})
                  },
                },
                {
                  width: 80, title: '隐患占比', key: 'happendRate', align: 'center', render: (h, params) => {
                    return h('span', params.index === this.categoryData.length - 1 ? {domProps: {innerHTML: fixed(params.row.gradeMap['重大隐患'].happendRate, 5, 2)}} : '-')
                  },
                },
              ]
            },
          ]
        },
        {
          width: 100, title: '概率', key: 'rate', align: 'center', render: (h, params) => {
            return h('span', {domProps: {innerHTML: fixed(params.row.rate, 5, 2)}})
          },
        },
        {
          width: 100, title: '隐患占比', key: 'happendRate', align: 'center', render: (h, params) => {
            return h('span', {domProps: {innerHTML: fixed(params.row.happendRate, 5, 2)}})
          },
        },
      ],
      params: {},
    }
  },
  computed: {
    categoryData() {
      // 十大项隐患统计相关
      let data = [];
      const {categoryData, gradeData} = this.commentData;
      data = Object.keys(categoryData).map((item, index) => {
        let itemData = categoryData[item];
        itemData.name = item;
        itemData.id = index;
        return itemData;
      })
      data.push({
        id: data.length,
        name: '合计',
        gradeMap: gradeData,
      });
      return data
    },
    summary() {
      let list = this.categoryData.slice(0, this.categoryData.length - 1).sort((a, b) => {
        return b['count'] - a['count']
      })
      return `<b>根据统计结果，隐患占比最高的为：${list[0]?.name}，其次为${list[1]?.name}、${list[2]?.name}，其中${list[0]?.name}为重大危险源之一，需引起重视，建议组织开展专项检查任务时可列入检查项目。</b>`
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style lang="less" scoped>
@componentSpacing: 20px;
@componentRadius: 6px;
.projectDangerProportion {
  margin-bottom: @componentSpacing;
  border-radius: @componentRadius;
  overflow: hidden;

  .ivu-card {
    padding-bottom: 50px;

    .ivu-card-head {
      height: 80px;
      line-height: 80px;
      text-align: center;
    }

    .ivu-card-body {
    }
  }
}
</style>