<template>
  <chartBox title="各子公司服务项目统计（个）">
    <div id="subsidiaryStatistics"></div>
  </chartBox>
</template>

<script>
import * as echarts from 'echarts';
import chartBox from '@/views/reportManager/month/detail/basicOverview/chart/chartBox.vue'
export default {
  name: 'subsidiaryStatistics',
  props: {
    companyData: {type:Object,return:()=>{return {}}}
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: 'transparent'
            }
          },
          confine: true
        },

        grid: {
          left: 0,
          right: 10,
          bottom:20,
          top: 20,
          containLabel: true
        },

        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: true,
              lineStyle: {
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: true,
              interval: 0,
              margin: 20,
              rotate: 20,
              align: "center"
            },
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
            },
          }
        ],
        series: [{
          name: '项目数（个）',
          type: "bar",
          barWidth: 20,
          barGap: 0,
          data: [],
          itemStyle: {
            color: "#f6b25d"
          },
          label: {
            show: true,
            position: 'top',
            offset: [5, -20],
          },
        },{
          tooltip: {
            show: false,
          },
          type: 'bar',
          barWidth: 10,
          barGap: 0,
          data: [],
          itemStyle: {
            color: "#f6b25d"
          },
        },{
          tooltip: {
            show: false
          },
          type: 'pictorialBar',
          itemStyle: {
            borderColor: '#47A6FF',
            color: '#f6b25d',
          },
          symbol: 'path://M 0,0 l 120,0 l -30,60 l -120,0 z',
          symbolSize: ['30', '10'],
          symbolOffset: ['0', '-11'],
          symbolPosition: 'end',
          data: [],
          z: 3
        }]
      },
      myChart: {},
    }
  },
  components: {
    chartBox,
  },
  computed: {
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById('subsidiaryStatistics'));
    this.getPolicyData();
  },
  methods: {
    getPolicyData() {
      const data = Object.values(this.companyData||{})
      const sideData = this.$parent.sideData(data)
      this.option.xAxis[0].data = Object.keys(this.companyData||{})
      this.option.series[0].data = this.option.series[2].data = data
      this.option.series[1].data = sideData

      this.myChart.clear();
      this.myChart.resize(
        {
          width:document.getElementById("subsidiaryStatistics").offsetWidth,
          height:document.getElementById("subsidiaryStatistics").offsetHeight
        }
      )
      this.myChart.setOption(this.option);
    }
  }
}
</script>

<style lang='less'>
#subsidiaryStatistics {
  height: 100%;
  width: 100%;
}
</style>