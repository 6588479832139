<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.iname">
    <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('机构报表状态') }}</Tag>
    <template slot="brief">
      <ys-cell title="报告类型" :label="detail.type|getBaseDataName('机构报表')"/>
      <ys-cell title="归属" :label="detail.index"/>
      <ys-cell title="接收机构" :label="detail.receiveOrgName"/>
    </template>
    <template slot="main">
      <chart :style="{height:height-100+'px'}" style="overflow-y: auto;width: 100%; " :detail="detail" v-if="detail.data"></chart>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {getMonthInfo} from "@/api/brief";
import chart from '@/views/reportManager/month/detail/index.vue'

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {chart},
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      getMonthInfo({id: this.detailID}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
        }
        this.setTagName(this.detail.iname)
      })
    }
  }
}
</script>

<style scoped>

</style>