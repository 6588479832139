<template>
  <div class="probabilityAnalysis">
    <Card :bordered="false" dis-hover>
      <b slot="title">概率分析</b>
      <div class="left" style="">
        <div id="probabilityAnalysisChart" style="height: 100%; width: 100%;"></div>
      </div>
      <div class="right" style="">
        <div class="title">隐患发生概率最高的类型：</div>
        <p class="item" v-for="(item, index) in List" :key="index">
          <template v-if="index<3">
          {{index + 1}}、{{item}}<span style=" padding-left: 20px; color: red">{{categoryData[item].happendRate|fixed(5,2)}}</span>
          </template>
        </p>
      </div>
    </Card>
  </div>
</template>

<script>
import ysAdmin from "ys-admin";
import * as echarts from 'echarts';

export default {
  name: 'probabilityAnalysis',
  props: {
    categoryData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{a}<br />{b}：{d} %'
        },
        series: [
          {
            name: '概率',
            type: 'pie',
            radius: [68, 88],
            center: ['65%', '50%'],
            itemStyle: {
              borderColor: ysAdmin.color.componentBackground,
              borderWidth: 5,
            },
            label: {
              distanceToLabelLine: 0
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ]
      },
      List: []
    }
  },
  computed: {},
  mounted() {
    this.myChart = echarts.init(document.getElementById("probabilityAnalysisChart"));
    this.getPolicyData();
  },
  methods: {
    getPolicyData() {
      this.option.series[0].data = Object.keys(this.categoryData).map(item => {
        return {
          name: item,
          value: this.categoryData[item]['happendRate'],
        }
      })
      this.List = Object.keys(this.categoryData).sort((a, b) => {
        return this.categoryData[b]['happendRate'] - this.categoryData[a]['happendRate']
      });
      this.myChart.setOption(this.option);
    }

  }
}

</script>

<style lang="less">
@componentSpacing: 20px;
@componentRadius: 6px;
.probabilityAnalysis {
  margin-bottom: @componentSpacing;
  border-radius: @componentRadius;
  overflow: hidden;

  .ivu-card {
    padding-bottom: 20px;

    .ivu-card-head {
      height: 80px;
      line-height: 80px;
    }

    .ivu-card-body {
      display: flex;

      .left {
        height: 220px;
        width: 60%;
        align-self: center;
      }

      .right {
        flex: 1;

        .title {
          line-height: 50px;
          font-weight: bold;
        }

        .item {
          line-height: 30px;
        }
      }
    }
  }
}

</style>