<template>
  <div class="basicOverview">
    <div class="title">
      <b>基本概况</b>
    </div>
    <div class="chartContent" :key="componentKey">
      <service-data class="chartBox" :projectNum="projectNum" :serviceNum="serviceNum"></service-data>
      <category-statistics class="chartBox" :projectCategory="projectCategory"></category-statistics>
      <subsidiary-statistics class="chartBox" :companyData="companyData"></subsidiary-statistics>
      <region-statistics class="chartBox" :ownArea="ownArea"></region-statistics>
      <Summary class="chartBox" :checkData="checkData"></Summary>
    </div>
  </div>
</template>

<script>
import serviceData from '@/views/reportManager/month/detail/basicOverview/chart/serviceData.vue'
import categoryStatistics from '@/views/reportManager/month/detail/basicOverview/chart/categoryStatistics.vue'
import subsidiaryStatistics from '@/views/reportManager/month/detail/basicOverview/chart/subsidiaryStatistics.vue'
import regionStatistics from '@/views/reportManager/month/detail/basicOverview/chart/regionStatistics.vue'
import Summary from '@/views/reportManager/month/detail/basicOverview/chart/summary.vue'
export default {
  name: 'basicOverview',
  props: ["commentData", "policyData"],
  data() {
    return {
      componentKey: 0,
      projectNum: 0,
      serviceNum: 0,
      projectCategory: [],
      companyData: {},
      ownArea: [],
      checkData: {}
    }
  },
  components: {
    serviceData,
    categoryStatistics,
    subsidiaryStatistics,
    regionStatistics,
    Summary,
  },
  computed: {},
  mounted() {
    if (JSON.stringify(this.commentData) !== '{}' && JSON.stringify(this.policyData) !== '{}') {
      this.projectNum = this.policyData.policyCount
      this.serviceNum = this.commentData.checkCount
      this.projectCategory = this.policyData.projectCategory
      this.companyData = this.policyData.companyData
      this.ownArea = this.policyData.ownArea
      this.checkData = this.commentData.checkData
      this.componentKey = 1
    }
  },
  methods: {
    sideData(data) {
      // 侧柱所需值为数据源中最大值除以2后再除以10
      const regularValue = Math.max(...data) % 2 == 0 ? Math.max(...data) / 20 : (Math.max(...data) + 1) / 20
      return data.map(item => {
        return item == 0 ? 0 : (regularValue < 0.2 ? item + regularValue / 2 : item + regularValue)
      })
    }
  }
}
</script>

<style lang="less">
@componentSpacing: 20px;
@componentRadius: 6px;
.basicOverview {
  margin-bottom: @componentSpacing;
  .title {
    height: 80px;
    line-height: 80px;
    margin-bottom: 5px;
    padding-left: 26px;
    border-radius: @componentRadius;
  }
  .chartContent {
    display: flex;
    .chartBox {
      margin: 0 5px;
      border-radius: @componentRadius;
      overflow: hidden;
      &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(5) {
        flex: 1;
      }
      &:nth-of-type(3), &:nth-of-type(4) {
        width: 22%;
      }
      &:nth-of-type(1) {
        margin-left: 0;
      }
      &:nth-last-of-type(1) {
        margin-right: 0;
      }
    }
  }
}
</style>