<template>
  <div class="chartBox">
    <Card :bordered="false" dis-hover>
      <b slot="title">{{ title }}</b>
      <div class="boxContent">
        <slot></slot>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  name: 'chartBox',
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="less">
@componentSpacing: 20px;
@componentRadius: 6px;
.chartBox {
  .ivu-card {
    height: 100%;
    width: 100%;
    .ivu-card-head {
      height: 50px;
      line-height: 50px;
    }
    .ivu-card-body {
      height: 300px;
      .boxContent {
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>